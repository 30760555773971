<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-plus</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          VEREINSBENUTZER ERSTELLEN
        </h3>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-4">
        <v-col cols="12">
          <v-card
            :dark="!template.light"
            :light="template.light"
            class="rounded-xl pa-4"
          >
            <v-row justify="center">
              <v-col
                cols="12"
                :md="new_user.rolle == 'Trainer' ? 5 : 10"
                class="py-0"
              >
                <v-select
                  filled
                  rounded
                  v-model="new_user.rolle"
                  :items="rollen"
                  :rules="[rules.required]"
                  label="Rolle"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="5"
                class="py-0"
                v-if="new_user.rolle == 'Trainer'"
              >
                <v-select
                  filled
                  rounded
                  v-model="new_user.team"
                  :items="teams"
                  item-text="name"
                  item-value="name"
                  :rules="[rules.required]"
                  multiple
                  label="Team"
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.vorname"
                  :rules="[rules.required, rules.name]"
                  label="Vorname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.nachname"
                  :rules="[rules.required, rules.name]"
                  label="Nachname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.email"
                  :rules="[rules.required, rules.email]"
                  label="E-Mail Adresse"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.email_confirm"
                  :rules="[rules.required, rules.email_confirm]"
                  label="E-Mail Adresse bestätigen"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="10" class="py-0">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  block
                  rounded
                  large
                  :loading="creating"
                  @click="save()"
                >
                  Benutzer anlegen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="created" persistent>
      <v-card>
        <v-row justify="center" class="text-center" style="min-height: 40vh;">
          <v-col cols="10" align-self="center">
            <h2>Benutzer wurde erstellt</h2>
          </v-col>
          <v-col cols="10" align-self="center">
            <v-btn color="white" large block outlined @click="back()">
              Weiter
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="error" multi-line color="danger">
      {{ errortext }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Zuschauer-erfassen',
  data() {
    return {
      new_user: {
        vorname: '',
        nachname: '',
        email: '',
        email_confirm: '',
        password: '',
        rolle: '',
        team: '',
        verein: {
          name: '',
          logo: '',
          id: '',
        },
      },
      teams: [],
      rollen: ['Trainer', 'Vorstand', 'Zuschauerkontrolle'],
      created: false,
      creating: false,
      valid: false,
      error: false,
      errortext: '',
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        email_confirm: (v) =>
          v == this.new_user.email
            ? true
            : 'Die E-Mail Adresse stimmt nicht überein.',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      characters: 'a-z,A-Z,0-9',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    this.teams = []
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Team')
      .orderBy('name')
      .get()
      .then((docRef) => {
        docRef.forEach((doc) => {
          this.teams.push(doc.data())
          var id = this.teams.length - 1
          this.teams[id].id = doc.id
        })
        this.teamsloaded = true
      })
      .catch((error) => {
        console.log(error)
      })
    this.generatePassword(8)
    this.new_user.verein = this.user.data.verein
    this.new_user.verein.id = this.user.data.uid
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    back() {
      router.push('/verein/benutzerverwaltung')
    },
    generatePassword(size) {
      let charactersArray = this.characters.split(',')
      let CharacterSet = ''
      let password = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }

      for (let i = 0; i < size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length),
        )
      }
      this.new_user.password = password
    },
    save() {
      if (this.$refs.form.validate()) {
        this.creating = true
        var functions = firebase.app().functions('europe-west3')
        var addUser = functions.httpsCallable('erstelleVereinsnutzer')
        addUser({ user: this.new_user, verband: this.user.data.verband })
          .then((result) => {
            if (result.data.success) {
              this.created = true
            } else {
              this.error = true
              this.errortext = result.data.errortext
            }
            this.creating = false
          })
          .catch((error) => {
            this.creating = false
            this.error = true
            this.errortext = error
          })
      }
    },
  },
}
</script>
